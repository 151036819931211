/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { jsx } from 'theme-ui'
import _ from 'lodash'
import { Box, SEO, Button, FormattedCurrency, TextOnTable } from '../../components'
import { usePositionProps } from './positionProps'
import { Table } from './Table'
import { GameMockType } from './types'
import { IUseGameActionsFn } from './useGameActions'
import { IGameData } from './useGameData'
import { Model } from '../../sharedBetweenServerAndClient__DO_NOT_EDIT/types'
import { OtherPlayerInformation } from './OtherPlayerInformation'
import { UsersCurrentTableAction } from './UsersCurrentTableAction'
import { UserProfilePicture } from '../../components/UserProfilePicture'
import { Ellipsis } from '../../components/Ellipsis'
import { Positioned } from '../../components/Positioned'
import { theme } from '../../gatsby-plugin-theme-ui/theme'
import { getFunction } from '../../utils/api'
import { AsyncActionButton } from '../../components/AsyncActionButton'
import { listOfThings } from '../../utils/formatting'

export const GameDetailsPotentialPlayer: React.FC<Pick<
  IGameData,
  'game' | 'users' | 'authUser'
>> = ({ game, users, authUser }) => {
  const windowSize = useWindowSize()
  const positionProps = usePositionProps({
    otherUserCount: game.userIds.length,
    hasGameChatVisible: false,
    windowSize,
  })
  const realIsPendingApproval = _.includes(game.pendingUserEmailAccessRequests, authUser.email)
  const isPendingApproval = realIsPendingApproval || !game.datetimeStarted
  useEffect(() => {
    if (!game.datetimeStarted && !realIsPendingApproval) {
      getFunction('requestAccessToGame')({ gameId: game.id })
    }
  }, [])
  const adminUsers = _.entries(game.userRoles)
    .filter(([userId, status]) => status === 'ADMIN')
    .map(([userId]) => users.find((user) => user.id === userId)!)
  const { otherUserPositioning, measurements } = positionProps
  return (
    <Box bg="background">
      <SEO title="Your Poker Game" noIndex />
      <Box
        css={{
          width: '100%',
          transition: 'all .3s ease-in',
          height: windowSize.height,
        }}
      >
        <Box mx="auto" css={{ width: measurements.tableAndPlayersWidth, position: 'relative' }}>
          <Box css={{ height: measurements.tableAndPlayersHeight }}>
            <Table measurements={measurements}>
              <Box>
                {isPendingApproval ? (
                  <TextOnTable sx={{ px: [2, null, 5] }}>
                    Waiting for approval from{' '}
                    {listOfThings(
                      adminUsers.map((user) => user.email),
                      'or',
                    )}
                  </TextOnTable>
                ) : (
                  <AsyncActionButton
                    size="lg"
                    sx={{ px: [2, null, 5] }}
                    variant="lightGreen"
                    loadingVariant="dark"
                    disabled={isPendingApproval}
                    errorLabel="requestAccessToGame"
                    action={() => getFunction('requestAccessToGame')({ gameId: game.id })}
                  >
                    Request access to game
                  </AsyncActionButton>
                )}
              </Box>
            </Table>
            {otherUserPositioning.map(({ position, calcPositionFromPixels }, index) => {
              const user = users[index]
              return (
                <React.Fragment key={index}>
                  <Positioned
                    width={measurements.offTableUserInfoWidth}
                    height={measurements.offTableUserInfoHeight}
                    coords={calcPositionFromPixels(
                      measurements.offTableUserInfoHeight / 2 + theme.space[3],
                    )}
                  >
                    <Box
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <UserProfilePicture
                        width={measurements.offTableUserInfoWidth / 1.75}
                        user={user}
                      />
                      <Ellipsis sx={{ textAlign: 'center', fontSize: [0, 0, 1] }}>
                        {user.email}
                      </Ellipsis>
                      <Ellipsis
                        sx={{ textAlign: 'center', fontSize: [1, 1, 2], color: 'lightText' }}
                      >
                        <FormattedCurrency amount={game.totalChipsGiven[user.id]} />
                      </Ellipsis>
                    </Box>
                  </Positioned>
                </React.Fragment>
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
