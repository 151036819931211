/** @jsx jsx */
import _ from 'lodash'
import React from 'react'
import { useWindowSize, useCopyToClipboard } from 'react-use'
import { jsx } from 'theme-ui'
import {
  Box,
  Button,
  Card,
  Col,
  Heading,
  Row,
  SEO,
  TextOnTable,
  Txt,
  LoadingPlaceholder,
  FormattedCurrency,
} from '../../components'
import { Ellipsis } from '../../components/Ellipsis'
import { FormattedUserName } from '../../components/FormattedUserName'
import { Positioned } from '../../components/Positioned'
import { UserProfilePicture } from '../../components/UserProfilePicture'
import { theme } from '../../gatsby-plugin-theme-ui/theme'
import { getFunction } from '../../utils/api'
import { PendingUserRequestButton } from './PendingUserRequest'
import { usePositionProps } from './positionProps'
import { Table } from './Table'
import { IGameData } from './useGameData'
import { maybePlural } from '../../utils/formatting'
import { AsyncActionButton } from '../../components/AsyncActionButton'

export const GameDetailsBeforeActiveGame: React.FC<Pick<
  IGameData,
  'game' | 'users' | 'authUser' | 'currentUserId'
>> = ({ game, users, currentUserId, authUser }) => {
  const [copyState, copyToClipboard] = useCopyToClipboard()
  const windowSize = useWindowSize()
  const otherUsers = users.filter((user) => user.id !== currentUserId)
  const positionProps = usePositionProps({
    otherUserCount: otherUsers.length,
    hasGameChatVisible: false,
    windowSize,
  })
  const { currentUserTablePositioning, otherUserPositioning, measurements } = positionProps
  const adminUserId = _.entries(game.userRoles).find(([userId, role]) => role === 'ADMIN')![0]
  const isAdmin = adminUserId === currentUserId
  const adminUser = users.find((user) => user.id === adminUserId)!
  const adminContent = (
    <Card sx={{ p: [3, 3, 4], m: 2 }}>
      <Txt sx={{ fontSize: [1, null, 2] }}>
        To invite players, send them a link to this page (click to copy).
      </Txt>

      <Button
        sx={{
          mb: 5,
          mt: 2,
          bg: 'white',
          color: 'darkBlue',
        }}
        onClick={() => copyToClipboard(window.location.href)}
        size="sm"
        block
      >
        <LoadingPlaceholder
          loadingVariant="dark"
          loading={!!copyState.value || !!copyState.error}
          opacity={0.05}
          loadingContent={
            copyState.value
              ? 'Copied to clipboard'
              : copyState.error
              ? "Copy the url from your browser's address bar"
              : window.location.href
          }
        >
          <Txt sx={{ fontSize: ['9px', null, null, 0] }}>{window.location.href}</Txt>
        </LoadingPlaceholder>
      </Button>
      <Row>
        <Col xs={{ size: 12, offset: 0 }} sm={{ size: 8, offset: 2 }} xl={{ size: 6, offset: 0 }}>
          <AsyncActionButton
            block
            loadingVariant="light"
            variant="lightGreen"
            disabled={game.userIds.length < 2}
            errorLabel="startGame"
            action={() => getFunction('startGame')({ gameId: game.id })}
          >
            Start Game
          </AsyncActionButton>
        </Col>
        <Col xs={{ size: 12, offset: 0 }} sm={{ size: 8, offset: 2 }} xl={{ size: 6, offset: 0 }}>
          <PendingUserRequestButton
            block
            game={game}
            sx={{ bg: 'white', color: 'darkBlue', mt: [3, null, null, null, null, 0] }}
            disabled={!game.pendingUserEmailAccessRequests.length}
          >
            {game.pendingUserEmailAccessRequests.length}{' '}
            {maybePlural('user', game.pendingUserEmailAccessRequests)} waiting to join
          </PendingUserRequestButton>
        </Col>
      </Row>
    </Card>
  )
  return (
    <Box bg="background">
      <SEO title="Your Poker Game" noIndex />
      <Box
        css={{
          width: '100%',
          transition: 'all .3s ease-in',
          height: windowSize.height,
        }}
      >
        <Box mx="auto" css={{ width: measurements.tableAndPlayersWidth, position: 'relative' }}>
          <Box css={{ height: measurements.tableAndPlayersHeight }}>
            <Table measurements={measurements}>
              {isAdmin ? (
                measurements.isLandscapeMode ? (
                  adminContent
                ) : null
              ) : (
                <TextOnTable>
                  Waiting for <FormattedUserName user={adminUser} /> to start the game
                </TextOnTable>
              )}
            </Table>
            {otherUserPositioning.map(({ position, calcPositionFromPixels }, index) => {
              const user = otherUsers[index]
              return (
                <React.Fragment key={index}>
                  <Positioned
                    width={measurements.offTableUserInfoWidth}
                    height={measurements.offTableUserInfoHeight}
                    coords={calcPositionFromPixels(
                      measurements.offTableUserInfoHeight / 2 + theme.space[3],
                    )}
                  >
                    <Box
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <UserProfilePicture
                        width={measurements.offTableUserInfoWidth / 1.75}
                        user={user}
                      />
                      <Ellipsis sx={{ textAlign: 'center', fontSize: [0, 0, 1] }}>
                        {user.email}
                      </Ellipsis>
                      <Ellipsis
                        sx={{ textAlign: 'center', fontSize: [1, 1, 2], color: 'lightText' }}
                      >
                        <FormattedCurrency amount={game.totalChipsGiven[user.id]} />
                      </Ellipsis>
                    </Box>
                  </Positioned>
                </React.Fragment>
              )
            })}
          </Box>
        </Box>
        {isAdmin && !measurements.isLandscapeMode && <Box sx={{ m: 4 }}>{adminContent}</Box>}
      </Box>
    </Box>
  )
}
