import React, { useMemo, useEffect } from 'react'
import firebase from 'gatsby-plugin-firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useDocumentData, useCollectionData } from '../../hooks/useFirebase'
import { hash } from '../../utils/hash'
import { Model } from '../../sharedBetweenServerAndClient__DO_NOT_EDIT/types'
import { ClientGameHandController } from '../../controllers/ClientGameHandController'
import { logger } from '../../utils/logger'
import _ from 'lodash'
import { deserializeUserHand } from '../../utils/userHandUtils'
import { Sentry, captureExceptionWithLabel } from '../../utils/sentry'

export interface IGameData {
  authUser: firebase.User
  game: Model.Game
  gameHands: Model.GameHand[]
  users: Model.User[]
  currentUserHand?: Model.UserHand
  currentHand: Model.GameHand
  currentUserId: string
}

const isUserOut = ({ currentUserId, currentHand }: Partial<IGameData>) => {
  if (!currentHand || !currentUserId) {
    return false
  }
  return currentHand.userTableStates[currentUserId] === 'OUT'
}

export const useGameData = (id: string) => {
  logger.debug('fetching data:', 'useGameData')
  const [authUser, authUserLoading, authUserError] = useAuthState(firebase.auth())
  const currentUserId = authUser && hash(authUser.email!)
  const fetchGame = useDocumentData<Model.Game>(firebase.firestore().doc(`/games/${id}`))
  const [game, gameLoading, gameError] = fetchGame
  const [maybeAllUsers, usersLoading, usersError] = useCollectionData<Model.User>(
    game ? firebase.firestore().collection(`/users`).where('id', 'in', game.userIds) : undefined,
  )
  const users =
    game && maybeAllUsers && game.userIds.length === maybeAllUsers.length
      ? maybeAllUsers
      : undefined
  const [gameHands, gameHandsLoading, gameHandsError] = useCollectionData<Model.GameHand>(
    currentUserId
      ? firebase
          .firestore()
          .collection(`/games/${id}/gameHands`)
          .limit(10)
          .where('userTablePosition', 'array-contains', currentUserId)
          .orderBy('datetimeCreated', 'desc')
      : undefined,
  )
  const [currentHand] = gameHands || []
  const [_currentUserHand, currentUserHandLoading, currentUserHandError] = useDocumentData<
    Model.SerializedUserHand
  >(
    currentHand && authUser
      ? firebase
          .firestore()
          .doc(`games/${currentHand.gameId}/gameHands/${currentHand.id}/userHands/${currentUserId}`)
      : undefined,
  )
  const currentUserHand = _currentUserHand ? deserializeUserHand(_currentUserHand) : undefined
  useEffect(() => {
    Sentry.configureScope(function (scope) {
      if (authUser && authUser.email) {
        scope.setUser({
          id: hash(authUser.email!),
          email: authUser.email!,
        })
      }
      if (game) {
        scope.setExtra('gameId', game.id)
      }
      if (currentHand) {
        scope.setExtra('currentHandId', currentHand.id)
      }
    })
  }, [authUser && authUser.email, game && game.id, currentHand && currentHand.id])
  const errors = { gameError, gameHandsError, usersError, currentUserHandError }
  const errorsArray = _.entries(errors).map(([key, error]) => ({ key, error }))
  useEffect(
    () => {
      errorsArray
        .filter((err) => !!err.error)
        .forEach(({ key, error }) => {
          Sentry.withScope((scope) => {
            scope.setExtra('queryType', key)
            captureExceptionWithLabel(error!, 'useGameData', scope)
          })
        })
    },
    errorsArray.map((err) => err.error),
  )
  if (!authUser && !authUserLoading) {
    return { state: 'unauthenticated' as 'unauthenticated' }
  }
  if (game && users && currentUserId && authUser) {
    if (!_.includes(game.userIds, currentUserId)) {
      return {
        game,
        users,
        currentUserId,
        authUser,
        state: 'authenticated-not-part-of-game' as 'authenticated-not-part-of-game',
      }
    }
    if (!game.datetimeStarted) {
      return {
        game,
        users,
        currentUserId,
        authUser,
        state: 'authenticated-before-game-starts' as 'authenticated-before-game-starts',
      }
    }
    if (
      gameHands &&
      (currentUserHand || isUserOut({ currentHand, currentUserId })) &&
      currentHand
    ) {
      const response: IGameData = {
        game,
        gameHands,
        users,
        currentUserHand,
        currentHand,
        currentUserId,
        authUser,
      }
      return { ...response, state: 'authenticated-active-game' as 'authenticated-active-game' }
    }
  }
  logger.info('nothing to return yet. Game data:', 'useGameData', {
    currentUserId,
    currentHand,
    data: {
      game,
      gameHands,
      users,
      currentUserHand,
      authUser,
    },
    errors,
    loading: {
      gameLoading,
      gameHandsLoading,
      usersLoading,
      currentUserHandLoading,
      authUserLoading,
    },
  })
}
