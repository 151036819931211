/** @jsx jsx */
import React, { useState } from 'react'
import { useWindowSize } from 'react-use'
import { jsx } from 'theme-ui'
import { Box, SEO, TextOnTable, Heading, Loading } from '../../components'
import { UnauthenticatedUserForm } from '../../components/UnauthenticatedUserForm'
import { usePositionProps } from './positionProps'
import { Table } from './Table'
import { useAuthenticateUserCallback } from '../../hooks/useAuthenticateUserCallback'

export const GameDetailsUnauthenticatedState: React.FC<{}> = ({}) => {
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const windowSize = useWindowSize()
  const positionProps = usePositionProps({
    otherUserCount: 8,
    hasGameChatVisible: false,
    windowSize,
  })
  useAuthenticateUserCallback({
    onStartAuth: () => {
      setIsAuthenticating(true)
    },
    onCompleteAuth: (auth) => {
      if (!auth) {
        setIsAuthenticating(false)
      }
    },
  })
  const { measurements } = positionProps

  return (
    <Box bg="background">
      <SEO title="Your Poker Game" noIndex />
      {measurements.isLandscapeMode || isAuthenticating ? (
        <Box
          css={{
            width: '100%',
            transition: 'all .3s ease-in',
            height: windowSize.height,
          }}
        >
          <Box mx="auto" css={{ width: measurements.tableAndPlayersWidth, position: 'relative' }}>
            <Box css={{ height: measurements.tableAndPlayersHeight }}>
              <Table measurements={measurements}>
                {isAuthenticating ? (
                  <Box sx={{ textAlign: 'center' }}>
                    <TextOnTable>Logging in...</TextOnTable>
                    <Box>
                      <Loading loadingVariant="light" />
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <TextOnTable sx={{ mb: 4 }}>
                      Welcome to OurPokerGame! Sign in to start playing.
                    </TextOnTable>
                    <UnauthenticatedUserForm backgroundColor="dark" />
                  </Box>
                )}
              </Table>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ p: 4 }}>
          <Heading sx={{ mb: 4, textAlign: 'center' }}>
            Welcome to OurPokerGame! Sign in to start playing.
          </Heading>
          <UnauthenticatedUserForm backgroundColor="light" />
        </Box>
      )}
    </Box>
  )
}
