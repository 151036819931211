import * as queryString from 'query-string'
import { WindowLocation } from '@reach/router'
import { useMemo } from 'react'

export const useQueryParams = <T = object>(
  location: WindowLocation,
  { required }: { required?: Array<keyof T> } = {},
) => {
  const params = useMemo(() => {
    const params: T = queryString.parse(location.search)
    return params
  }, [])
  return { params }
}
