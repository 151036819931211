import { ClientGameHandController } from '../../controllers/ClientGameHandController'
import { useMemo } from 'react'
import { getFunction } from '../../utils/api'
import { Model } from '../../sharedBetweenServerAndClient__DO_NOT_EDIT/types'
import { IGameActions } from './types'

const createGameHandActionFn = getFunction('createGameHandAction')
const setCurrentUserTableStateFn = getFunction('setCurrentUserTableState')
const voteToSetUserTableStateFn = getFunction('voteToSetUserTableState')
const giveUserAdditionalChipsFn = getFunction('giveUserAdditionalChips')

export type IUseGameActionsFn = (controller: ClientGameHandController) => IGameActions

export const useGameActions = ({
  isMockGame,
}: { isMockGame?: boolean } = {}): IUseGameActionsFn => (controller) => {
  return useMemo(() => {
    const _testingUserId = isMockGame ? controller.gameHand.nextUserIdToAct : undefined
    const gameHandId = controller.gameHand.id
    const gameId = controller.gameHand.gameId

    const fns: IGameActions = {
      giveUserAdditionalChips: (payload) => {
        return giveUserAdditionalChipsFn({ ...payload, gameHandId, gameId })
      },
      check: () => {
        return createGameHandActionFn({
          _testingUserId,
          type: 'CHECK',
          gameHandId,
          gameId,
        })
      },
      voteToSetUserTableState: (config) => {
        return voteToSetUserTableStateFn({
          ...config,
          gameHandId,
          gameId,
        })
      },
      setCurrentUserTableState: (state: Model.UserTableState) => {
        return setCurrentUserTableStateFn({
          state,
          gameHandId,
          gameId,
        })
      },
      fold: () => {
        return createGameHandActionFn({
          _testingUserId,
          type: 'FOLD',
          gameHandId,
          gameId,
        })
      },
      bet: ({ chipsAmount }) => {
        return createGameHandActionFn({
          _testingUserId,
          chipsAmount,
          type: 'BET',
          gameHandId,
          gameId,
        })
      },
    }
    return fns
  }, [controller])
}
