import * as fbHooks from 'react-firebase-hooks/firestore'
import firebase from 'gatsby-plugin-firebase'

export const useDocumentData = <T>(
  docRef?: firebase.firestore.DocumentReference | null | undefined,
  options?:
    | {
        idField?: string | undefined
        snapshotListenOptions?: firebase.firestore.SnapshotListenOptions | undefined
      }
    | undefined,
): [T | undefined, boolean, Error | undefined] => {
  const [data, loading, error] = fbHooks.useDocumentData<T>(docRef, options)
  if (!data && !loading && docRef) {
    // throw new Error('Resource does not exist')
  }
  return [data, loading, error]
}

export const useCollectionData = <T>(
  query?: firebase.firestore.Query | null | undefined,
  options?:
    | {
        idField?: string | undefined
        snapshotListenOptions?: firebase.firestore.SnapshotListenOptions | undefined
      }
    | undefined,
): [T[] | undefined, boolean, Error | undefined] => {
  const [data, loading, error] = fbHooks.useCollectionData<T>(query, options)
  if (!data && !loading && query) {
    // throw new Error('Resource does not exist')
  }
  return [data, loading, error]
}
