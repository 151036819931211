/** @jsx jsx */
import React, { useCallback } from 'react'
import { usePrevious, useTimeout } from 'react-use'
import { jsx } from 'theme-ui'
import { appConfig } from '../../appConfig'
import { AuthenticatedPage } from '../../components/AuthenticatedPage/AuthenticatedPage'
import { useQueryParams } from '../../hooks/useQueryParams'
import { GameDetailsActiveGame } from './GameDetailsActiveGame'
import { GameDetailsPotentialPlayer } from './GameDetailsPotentialPlayer'
import { GameDetailsUnauthenticatedState } from './GameDetailsUnauthenticatedState'
import { useGameActions } from './useGameActions'
import { useGameData } from './useGameData'
import { GameDetailsBeforeActiveGame } from './GameDetailsBeforeActiveGame'
import { logger } from '../../utils/logger'
import { usePreviousConditional } from '../../hooks/usePreviousConditional'
import { LoadingPage } from '../../components/LoadingPage'
import { AppPageProps } from '../../types'

const MemoGameDetailsActiveGame = React.memo(GameDetailsActiveGame)
export const FetchingGameDetails: React.FC<AppPageProps> = (props) => {
  const isMockGame = true
  const [isReady] = useTimeout(appConfig.ENVIRONMENT === 'local' ? undefined : 1400)
  const { params } = useQueryParams<{ id: string }>(props.location, { required: ['id'] })
  const gameId = params.id
  const currentGameData = useGameData(gameId)
  const previousGameData = usePreviousConditional(currentGameData, (gameData) => !!gameData)
  const gameData = currentGameData || previousGameData
  const getActions = useCallback(useGameActions({ isMockGame }), [])

  if (!gameData || !isReady()) {
    return <LoadingPage title="Your Poker Game" noIndex />
  }
  logger.info(`current gameData state: ${gameData.state}`, 'GameDetails', gameData)
  switch (gameData.state) {
    case 'unauthenticated': {
      return <GameDetailsUnauthenticatedState />
    }
    case 'authenticated-active-game': {
      return <MemoGameDetailsActiveGame {...{ gameData, getActions, ...props }} />
    }
    case 'authenticated-before-game-starts': {
      return <GameDetailsBeforeActiveGame {...gameData} />
    }

    case 'authenticated-not-part-of-game': {
      return <GameDetailsPotentialPlayer {...gameData} />
    }
  }
}
export const GameDetails: React.FC<AppPageProps> = (props) => {
  if (appConfig.IS_BROWSER) {
    // set a key so that when a user authenticated, we re-render the entire tree to re-fetch the authenticated user
    return <FetchingGameDetails key={props.location.href} {...props} />
  }
  return <LoadingPage title="Your Poker Game" noIndex />
}
