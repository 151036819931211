import { useRef, useEffect } from 'react'

export const usePreviousConditional = <T>(
  state: T,
  shouldUpdate: (state: T) => boolean,
): T | undefined => {
  const ref = useRef<T>()

  useEffect(() => {
    if (shouldUpdate(state)) {
      ref.current = state
    }
  })

  return ref.current
}
